<template>
  <div class="exportDownload">
    <el-dialog
      :visible.sync="ModalFlagSuccess"
      width="354px"
      height="200px"
      top="230px"
      title="导出提示"
    >
      <div style="text-align: center; font-size: 20px">
        {{ exportMsg }}已成功导出！
      </div>
      <div style="text-align: right; margin-top: 38px">
        <el-button type="primary" @click="close" style="margin-bottom: 20px"
          >知道了（{{ exportLeftSecond }}S）</el-button
        >
        <div class="clear"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'exportDownload',
  data() {
    return {
      ModalFlagSuccess: false,
      exportMsg: '',
      exportLeftSecond: ''
    }
  },
  methods: {
    show(data) {
      if (data) {
        this.exportMsg = data.exportMsg
        this.exportLeftSecond = data.exportLeftSecond
        this.ModalFlagSuccess = true
        let timer = null
        timer = setInterval(() => {
          if (this.exportLeftSecond > 0) {
            this.exportLeftSecond--
          } else {
            clearInterval(timer)
            this.ModalFlagSuccess = false
          }
        }, 1000)
      }
    },
    close() {
      this.ModalFlagSuccess = false
    }
  }
}
</script>

<style lang="scss" scoped></style>
