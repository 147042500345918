<template>
  <div class="arisePocCardTablePrivate">
    <PocCardTable class="topPocCardTable">
      <template v-slot:headerTitle>权限列表</template>
      <template v-slot:headerGroup>
        <el-input
          prefix-icon="el-icon-search"
          class="roleName"
          placeholder="权限名称"
          v-model="permissionName"
        />
        <el-button role="export" size="mini" v-permission @click="search"
          >查询</el-button
        >
        <el-button
          role="export"
          size="mini"
          v-permission
          @click="exportDownload"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="mini"
          @click="addRole"
          role="add"
          v-permission
          >新增权限</el-button
        >
        <el-button
          type="primary"
          v-if="isAllDelete"
          @click="onDelete(selectValList)"
          >批量删除</el-button
        >
      </template>
      <PocTable
        ref="tTableRef"
        v-loading="loading"
        :data="tableData"
        :current-page.sync="pageConfig.pageNum"
        :total="pageConfig.total"
        stripe
        :max-height="1000000000"
        @selection-change="handleSelectionChange"
        @size-change="pageSizeChange"
        @page-current-change="pageChange"
        @row-click="rowClick"
        @select-all="selectAll"
        highlight-current-row
      >
        <el-table-column type="selection" width="50" />
        <el-table-column
          v-for="item in columnConfig"
          :key="item.key"
          :prop="item.key"
          :label="item.name"
          :fixed="item.fixed"
          :sortable="item.sortable"
          :width="item.key === 'no' ? 60 : null"
          :min-width="item.width"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column label="操作" width="130" fixed="right" align="center">
          <template slot-scope="scope">
            <svg-icon
              role="edit"
              v-permission
              icon="tableEdit"
              title="编辑"
              class="tablePointSvgClass"
              @click.native.stop="onEdit(scope.row)"
            ></svg-icon>
            <svg-icon
              role="delete"
              v-permission
              icon="tableDelete"
              title="删除"
              class="tablePointSvgClass"
              @click.native.stop="onDelete(scope.row)"
            ></svg-icon>
          </template>
        </el-table-column>
      </PocTable>
      <DownloadDialog ref="DownloadDialogRef" />
    </PocCardTable>
  </div>
</template>

<script>
import { columnConfig } from '@/views/menuRoleManage/TableColumnConfig'
import {
  sysPermissionsDelete,
  sysPermissionsList,
  sysUserExport
} from '@/api/role'
import { check, debounce, typeRange } from '@/utils/util'
import DownloadDialog from '@/views/menuRoleManage/components/DownloadDialog'
import { getItem, removeItem, setItem } from '@/utils/storage'
const { Message } = $PCommon
export default {
  name: 'menuRoleManage',
  mixins: [$PCommon.TableMixin],
  components: {
    DownloadDialog
  },
  data() {
    return {
      loading: false,
      columnConfig,
      tableData: [],
      permissionName: '',
      selectValList: [],
      isAllDelete: false,
      maxHeight: ''
    }
  },
  computed: {},
  created() {
    let h =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    this.maxHeight = h - 205
  },
  mounted() {
    this.queryApi()
  },
  beforeRouteLeave(to, from, next) {
    if (!to.path.includes('system/permissionManage')) {
      removeItem('preRowClick')
    }
    next()
  },
  methods: {
    search() {
      this.queryApi()
    },
    exportDownload() {
      const data = {
        permissionName: this.permissionName,
        ids: this.selectValList.map((item) => item.id).toString()
      }
      sysUserExport(data)
      // this.$refs.DownloadDialogRef.show({
      //   exportMsg: '权限数据',
      //   exportLeftSecond: 3
      // })
    },
    selectAll(val) {
      if (val.length) {
        this.isAllDelete = true
      } else {
        this.isAllDelete = false
      }
    },
    addRole() {
      const id = ''
      this.$router.push({
        path: '/system/permissionManage/edit',
        query: {
          id,
          status: 'add'
        }
      })
    },
    rowClick(val) {
      setItem('preRowClick', val)
      if (val) {
        const id = val.id
        this.$router.push({
          path: '/system/permissionManage/details',
          query: {
            id
          }
        })
      } else {
        return new Error('当前点击无返回，检查接口数据')
      }
    },
    handleSelectionChange(val) {
      this.selectValList = val
    },
    inputPermissionName() {
      debounce(this.queryApi, 1000)()
    },
    onDelete(val) {
      this.$confirm('此操作将除该信息，是否继续？', '提示', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        if (check(val) === typeRange.object) {
          const obj = {
            ids: String(val.id)
          }
          sysPermissionsDelete(obj).then((res) => {
            if (res.code === 200) {
              Message.success(res.msg)
              this.queryApi()
            }
          })
          this.queryApi()
        } else if (check(val) === typeRange.array) {
          const obj = {
            ids: val.map((item) => item && item.id).toString(',')
          }
          sysPermissionsDelete(obj).then((res) => {
            if (res.code === 200) {
              Message.success(res.msg)
              this.queryApi()
            }
          })
        }
      })
    },
    onEdit(val) {
      if (val) {
        const id = val.id
        this.$router.push({
          path: '/system/permissionManage/edit',
          query: {
            id,
            status: 'edit'
          }
        })
      } else {
        return new Error('当前点击无返回，检查接口数据')
      }
    },
    queryApi() {
      this.loading = true
      this.tableData = []
      const obj = {
        pageNum: this.pageConfig.pageNum,
        pageSize: this.pageConfig.pageSize,
        permissionName: this.permissionName
      }
      sysPermissionsList(obj)
        .then((res) => {
          if (res && res.code === 200) {
            this.loading = false
            if (res.data && res.data.list) {
              if (check(res.data.list) === typeRange.array) {
                this.tableData = res.data.list
                this.$nextTick(() => {
                  const preRowClick = getItem('preRowClick')
                  if (preRowClick) {
                    const data = this.tableData.find(
                      (item) => item.id === preRowClick.id
                    )
                    this.$refs.tTableRef.setCurrentRow(data, true)
                  }
                })
              }
              this.pageConfig.total = res.data.total
            }
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.arisePocCardTablePrivate {
  :deep(.pcth-title-box) {
    .pcth-title-right {
      display: flex;
      .roleName {
        margin-right: 16px;
        .el-input__inner {
          height: 30px;
        }
      }
    }
  }
  .disBody {
    :deep(.el-card__body) {
      display: none;
    }
  }
}

.iconImg {
  width: 16px;
  height: 16px;
  margin-right: 25px;
  position: relative;
  top: 3.5px;
}
</style>
